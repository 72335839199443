import { Button, Checkbox, FormControlLabel } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import AppContext, { AppContextType } from '../../../AppContext'
import InputText from '../../../components/Form/Input/Input'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import Employee from '../../../models/Employee/employee'
import User, { UsersList, UserToSend } from '../../../models/User/user'
import { getEmployeesWithoutUser } from '../../../services/Employee/EmployeeService'
import { createUser, getUserByIdentifier, updateUser } from '../../../services/User/UserService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { getAllAccessLevels } from '../../../services/Settings/acessLevelService'
import InputSelect2Multiple from '../../../components/Form/Select/Select2Multiple'
import PasswordView from './PasswordView/PasswordView'

const UserForm: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>()
  const [passwordWillBeChanged, setPasswordWillBeChanged] = React.useState<boolean>(false)

  const yupPasswordValidationRules = yup
    .string()
    .matches(/[0-9]/, 'A senha tem que ter, no mínimo, um número')
    .matches(/[a-z]/, 'A senha tem que ter, no mínimo, uma letra minúscula')
    .matches(/[A-Z]/, 'A senha tem que ter, no mínimo, uma letra maiúscula')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'A senha tem que ter, no mínimo, um caractere especial')
    .min(12, 'A senha tem que ter, no mínimo, 12 caracteres')

  const validationSchema = yup.object({
    username: yup.string().required('campo obrigatório').email('email inválido'),
    password: passwordWillBeChanged
      ? yupPasswordValidationRules.required('campo obrigatório')
      : yupPasswordValidationRules,
    idEmployee: yup.number().required('campo obrigatório'),
    idAuthorities: yup.array().min(1, 'Usuário deve ter ao menos uma permissão').required('campo obrigatório'),
    enabled: yup.boolean(),
  })
  const resolver = useYupValidationResolver(validationSchema)

  const { setTitle, setCustomHeaderContent, setIsShowLoading, showAlert, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const { control, formState, handleSubmit, reset, setValue, watch } = useForm<User>({
    resolver,
  })
  const navigate = useNavigate()
  const [employees, setEmployees] = React.useState<{ value: number; label: string }[]>([])
  const [acessLevelsList, setAcessLevelsList] = React.useState<{ value: number; label: string }[]>([])
  const [loadingCount, setLoadingCount] = React.useState<number>(0)
  const [showPasswordModalView, setShowPasswordModalView] = React.useState<boolean>(false)
  const [password, setPassword] = React.useState<string>('')

  const setLoadingOn = (presentLoadingCount: number): number => presentLoadingCount + 1
  const setLoadingOff = (presentLoadingCount: number): number => presentLoadingCount - 1
  const showLoading = (): boolean => loadingCount !== 0

  React.useEffect(() => {
    setIsShowLoading(showLoading())
  }, [loadingCount])

  const handleNoEmployees = (): void => {
    setIsShowLoading(false)
    showAlert('warning', 'Não há funcionários sem usuário cadastrados.')
  }

  const setEmployeesData = (employeesData: Employee[]): void => {
    const mappedEmployees = employeesData.map((employee) => ({
      value: employee.idEmployees as number,
      label: employee.name,
    }))

    if (employeesData.length === 0 && !id && !showPasswordModalView) {
      handleNoEmployees()
    }

    setEmployees(mappedEmployees)
  }

  const handleEmployeeChangeOnLoad = (): void => {
    setLoadingCount(setLoadingOn)
    getEmployeesWithoutUser()
      .then(setEmployeesData)
      .catch(() => showAlert('error', 'Erro ao carregar funcionários. Tente novamente mais tarde.'))
      .finally(() => {
        setLoadingCount(setLoadingOff)
      })
  }

  const setDefaultValues = React.useCallback(
    (user: UsersList): void => {
      reset({
        username: user.userName,
        idEmployee: user.idEmployee,
        idAuthorities: user.cargoIds,
        enabled: user.enabled,
      })
    },
    [reset]
  )

  const isBasicAccessSelected = (accessIdList: number[]): boolean => {
    return accessIdList.includes(2)
  }

  const handleAccessLevelChange = (_event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    const types = value ? (value.map((type) => type.value) as number[]) : []
    if (!isBasicAccessSelected(types)) {
      showAlert('warning', 'Não é possível remover o Acesso Básico. Contate o suporte.')
      types.push(2)
    }
    setValue('idAuthorities', types)
  }

  const handleEmployeeChange = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('idEmployee', value?.value as number)
  }

  const handleUserInfoIfEditPage = (): void => {
    if (id) {
      setLoadingCount(setLoadingOn)
      getUserByIdentifier(id)
        .then((user) => {
          reset({
            username: user.username,
            idEmployee: user.employeeId,
            enabled: user.enabled,
          })

          const cargosList = user.cargos.map((c) => c.id)
          if (!isBasicAccessSelected(cargosList)) {
            cargosList.push(2)
          }
          setValue('idAuthorities', cargosList)

          setEmployees([{ value: user.employeeId, label: user.employeeName }])
        })
        .catch(() => {
          showAlert('error', 'Erro ao carregar usuário. Tente novamente mais tarde.')
          navigate('/main/user/list')
        })
        .finally(() => {
          setLoadingCount(setLoadingOff)
        })
    } else {
      setValue('idAuthorities', [2])
    }
  }

  const handleAccessLevels = (): void => {
    setLoadingCount(setLoadingOn)
    getAllAccessLevels()
      .then((data) => {
        const acessLevelList = data.map((AL) => ({
          value: AL.idCargo as number,
          label: AL.name,
        }))
        setAcessLevelsList(acessLevelList)
      })
      .catch(() => {
        showAlert('error', 'Erro ao carregar níveis de acesso. Tente novamente mais tarde.')
      })
      .finally(() => {
        setLoadingCount(setLoadingOff)
      })
  }

  const handleInit = (): void => {
    handleEmployeeChangeOnLoad()
    handleUserInfoIfEditPage()
    handleAccessLevels()
  }

  React.useEffect(() => {
    handleInit()
    setTitle(id ? 'Editar Usuário' : 'Novo Usuário')
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar usuários', path: '/main/user/list' },
      { label: id ? 'Editar usuário' : 'Novo usuário', path: '/main/user/form' },
    ])
    setCustomHeaderContent(<div />)
  }, [id, setCustomHeaderContent, setDefaultValues, setTitle, showAlert, setItemsBreadCrumb])

  const submitUserUpdate = (user: UserToSend, userId: string): void => {
    updateUser({ ...user }, userId)
      .then(() => {
        showAlert('success', 'Usuário alterado com sucesso.')
        setLoadingCount(setLoadingOff)
        navigate('/main/user/list')
      })
      .catch(() => {
        showAlert('error', 'Erro ao editar usuário. Tente novamente mais tarde.')
      })
      .finally(() => setLoadingCount(setLoadingOff))
  }

  const submitUserCreation = (user: UserToSend): void => {
    createUser(user)
      .then(() => {
        showAlert('success', 'Usuário salvo com sucesso.')
        setShowPasswordModalView(true)
        handleEmployeeChangeOnLoad()
        reset()
      })
      .catch(() => {
        showAlert('error', 'Erro ao salvar usuário. Tente novamente mais tarde.')
      })
      .finally(() => setLoadingCount(setLoadingOff))
  }

  const generateRandomPassword = (): string => {
    const base36 = 36
    const sliceLength = -12
    const specialCharacters = '!@#$%^&*()_-+=<>?'
    const randomSpecialChar = specialCharacters[Math.floor(Math.random() * specialCharacters.length)]

    const rawPassword = Math.random().toString(base36).slice(sliceLength)
    const upperCaseSegment = rawPassword.slice(0, 4).toUpperCase()
    const lowerCaseSegment = rawPassword.slice(4, 12)

    const specialCharacterRandomPosition = Math.floor(Math.random() * lowerCaseSegment.length)

    const lowerCaseWithSymbol =
      lowerCaseSegment.slice(0, specialCharacterRandomPosition) +
      randomSpecialChar +
      lowerCaseSegment.slice(specialCharacterRandomPosition)

    const randomPassword = upperCaseSegment + lowerCaseWithSymbol
    setPassword(randomPassword)

    return randomPassword
  }

  const onSubmit: SubmitHandler<User> = (data: User) => {
    setLoadingCount(setLoadingOn)

    const user: UserToSend = { ...data, idAuthorities: data.idAuthorities }

    if (!id) {
      user.password = generateRandomPassword()
    }

    if (!data.enabled) user.enabled = false

    if (id) {
      submitUserUpdate(user, id)
    } else {
      submitUserCreation(user)
    }
  }

  const handleClosePasswordView = (): void => {
    setShowPasswordModalView(false)

    if (employees.length === 0 && !id && !showPasswordModalView) {
      handleNoEmployees()
    }
  }

  return (
    <>
      <PasswordView
        open={showPasswordModalView}
        handleModalClose={handleClosePasswordView}
        password={password}
        showAlert={showAlert}
      />
      <Card className="card card--form card-forms-styles" variant="outlined">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="idEmployee"
                control={control}
                render={({ field }) => (
                  <InputSelect2
                    id="idEmployee"
                    label="Funcionário"
                    errorText={formState.errors?.idEmployee?.message}
                    options={employees}
                    disabled={!!id}
                    value={field.value}
                    onChange={handleEmployeeChange}
                    clearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="username"
                    label="Email Usuário"
                    errorText={formState.errors?.username?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {passwordWillBeChanged && (
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      id="password"
                      type="password"
                      label="Senha Usuário"
                      disabled={!passwordWillBeChanged}
                      errorText={formState.errors?.password?.message}
                      {...field}
                    />
                  )}
                />
              )}
              {id && (
                <Button size="small" onClick={() => setPasswordWillBeChanged((b) => !b)}>
                  Alterar senha
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="idAuthorities"
                control={control}
                render={({ field }) => (
                  <InputSelect2Multiple
                    id="idAcessLevel"
                    label="Permissão"
                    errorText={(formState.errors?.idAuthorities as unknown as FieldError)?.message}
                    options={acessLevelsList}
                    value={watch('idAuthorities')}
                    onChange={handleAccessLevelChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="enabled"
                control={control}
                defaultValue
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="enabled"
                        checked={field.value as boolean}
                        data-cy="user-enabled-checkbox"
                        {...field}
                      />
                    }
                    label="Usuário Habilitado"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '24px',
            }}
          >
            <Button
              variant="contained"
              disabled={employees.length <= 0}
              color="primary"
              type="submit"
              data-cy="user-form-submit-button"
            >
              Salvar
            </Button>
          </Grid>
        </form>
      </Card>
    </>
  )
}

export default UserForm
